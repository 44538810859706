
@font-face {
  font-family: 'CancerfondenSans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('./../../fonts/CancerfondenSans/CancerfondenSans-Light.woff2?s5EbZj2') format('woff2'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-Light.woff?s5EbZj2') format('woff'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-Light.ttf?s5EbZj2') format('truetype');
}

@font-face {
  font-family: 'CancerfondenSans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: url('./../../fonts/CancerfondenSans/CancerfondenSans-LightItalic.woff2?s5EbZj2') format('woff2'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-LightItalic.woff?s5EbZj2') format('woff'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-LightItalic.ttf?s5EbZj2') format('truetype');
}

@font-face {
  font-family: 'CancerfondenSans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('./../../fonts/CancerfondenSans/CancerfondenSans-Regular.woff2?s5EbZj2') format('woff2'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-Regular.woff?s5EbZj2') format('woff'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-Regular.ttf?s5EbZj2') format('truetype');
}

@font-face {
  font-family: 'CancerfondenSans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: url('./../../fonts/CancerfondenSans/CancerfondenSans-RegularItalic.woff2?s5EbZj2') format('woff2'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-RegularItalic.woff?s5EbZj2') format('woff'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-RegularItalic.ttf?s5EbZj2') format('truetype');
}

@font-face {
  font-family: 'CancerfondenSans';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('./../../fonts/CancerfondenSans/CancerfondenSans-Bold.woff2?s5EbZj2') format('woff2'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-Bold.woff?s5EbZj2') format('woff'),
       url('./../../fonts/CancerfondenSans/CancerfondenSans-Bold.ttf?s5EbZj2') format('truetype');
}

@font-face {
  font-family: 'CancerfondenSans';
  font-style: normal;
  font-weight: 800;
  font-display: auto;
  src: url('../../fonts/CancerfondenSans/CancerfondenSans-ExtraBold.woff2?s5EbZj2') format('woff2'),
       url('../../fonts/CancerfondenSans/CancerfondenSans-ExtraBold.woff?s5EbZj2') format('woff'),
       url('../../fonts/CancerfondenSans/CancerfondenSans-ExtraBold.ttf?s5EbZj2') format('truetype');
}

@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('../../fonts/Colfax/Colfax-Light.woff2') format('woff2'),
       url('../../fonts/Colfax/Colfax-Light.woff') format('woff');
}

@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../../fonts/Colfax/Colfax-Regular.woff2') format('woff2'),
       url('../../fonts/Colfax/Colfax-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url('../../fonts/Colfax/Colfax-Medium.woff2') format('woff2'),
       url('../../fonts/Colfax/Colfax-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('../../fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
       url('../../fonts/OpenSans/OpenSans-Light.woff') format('woff'),
       url('../../fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../../fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
       url('../../fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
       url('../../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url('../../fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
       url('../../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
       url('../../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'IcoMoon';
  font-display: auto;
  src: url('../../fonts/IcoMoon/IcoMoon.woff2?ao039ojc') format('woff2'),
       url('../../fonts/IcoMoon/IcoMoon.woff?ao039ojc') format('woff'),
       url('../../fonts/IcoMoon/IcoMoon.ttf?ao039ojc') format('truetype');
}
