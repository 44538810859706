
.packer {
  spacer(1.5rem);
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  /*
  > * {
    box(red);
  }
  */
}
