
.articleThumb {
  &__wrapper {
    type-link(color = colors.text);
    white-space: normal;

    &--disableHover {
      &:hover {
        color: @color;
      }
    }
  }

  &__thumb {
    position: relative;
  }

  &__image {
    display: block;
    max-width: 100px;
    float: left;
    margin: 0 5% 0.25em 0;

    +mq(from: 'small') {
      max-width: none;
      width: 100%;
      float: none;
      margin: 0 0 1.25em 0;
    }
  }

  &__info { }

  &__heading {
    heading4();
    margin-bottom: 0.25rem;
  }

  &__date {
    color: #888;
    margin-bottom: 0.5em;
    font-size: 0.875em;
  }

  &__preamble {
    description1();

    +mq(until: 'tiny') {
      clear: both;
    }

  }

  // Used for RB17 SET case-list
  &__preamble2 {
    description1();
  }

  &__link {
    description1();
    display: inline-block;
    margin-top: 0.5em;
  }

  /* OLD STYLING FOR LABELS AND EMBED ICONS */
  &__label {
    font('cancerfondensans-bold');
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background: lighten(colors.oceanBlue, 80%);
    color: colors.oceanBlue;
    display: block;
    padding: 0.2em 0.5em;
    font-size: 0.875em;

    +mq(until: 'small') {
      top: 0;
      left: 0;
      padding: 0.1em 0.35em;
      display: none;
    }
  }

  &__playIcon {
    &:before {
      icon-play-solid();
      top: 0;
    }

    color: #fff;
    text-shadow: 0 1px 0 rgba(#000, 0.4);
    position: absolute;
    font-size: 4.5em;
    line-height: 1;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: block;
    height: 1em;
    width: @height;
    margin: @height*-0.5 0 0 @height*-0.5;

    +mq(until: 'small') {
      display: none;
    }
  }
}
