
.intro {
  spacer(0.5rem);
  margin-left: auto;
  margin-right: auto;

  &__title {
    &--sizeLarge {
      heading1();
    }

    &--sizeMedium {
      heading2();
    }

    &--sizeSmall {
      heading3();
    }

    &--weightLight {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 300;
    }

    &--weightNormal {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 400;
    }
  }

  &__preamble {
    preamble2('bold');

    &--weightLight {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 300;
    }

    &--weightNormal {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 400;
    }
  }

  &__description {
    description1();
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;

    +mq(until: 'small') {
      br {
        display: none;
      }
    }
  }
}
