
html {
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: visible;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;

  &:after {
    display: none;
    content: mq-breakpoints-to-json();
  }
}

html {
  font-size: 16px;
  line-height: 1.5;
  font-family: 'CancerfondenSans', sans-serif;
  font-weight: 400;
  color: colors.text;
  background: #fff;
  background-size: 32px 32px;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: 1em;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

p {
  font-size: 1rem;
  line-height: 1.5;

  +mq(from: 'small') {
    font-size: 1.125rem;
  }
}

b, strong, em, i {
  font-style: normal;
}

a {
  text-decoration: none;
  color: colors.darkBlue;
  transition: color 0.3s ease;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}