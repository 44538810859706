
.tmcCheckbox {
  display: inline-block;
  position: absolute;
  text-align: left;

  &.is-invalid {
    color: colors.errorRed;
  }

  &.is-disabled {
    color: colors.grayText;
  }

  &__input {
    display: none;
  }

  &__label {
    description2();
    cursor: pointer;
    white-space: nowrap;

    .tmcCheckbox.is-disabled &,
    .tmcCheckbox:hover.is-disabled & {
      cursor: default;
    }
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
    padding-left: 0.75em;
    white-space: normal;
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid rgba(colors.grayDust, 0.5);
    background: colors.grayAlabaster;
    transition: border 0.15s ease, background 0.15s ease;

    .tmcCheckbox:hover & {
      .no-touchevents & {
        background: #fff;
        border-color: colors.grayDust;

        &:after {
          background: colors.oceanBlue;
        }
      }
    }

    .tmcCheckbox.is-invalid &,
    .tmcCheckbox.is-invalid:hover & {
      background: colors.errorFadedRed;
      border-color: colors.errorRed;
    }

    .tmcCheckbox.is-disabled &,
    .tmcCheckbox.is-disabled:hover & {
      background: colors.grayAlabaster;
      border-color: rgba(colors.grayDust, 0.5);

      &:after {
        opacity: 0.5;
        background: transparent;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      transition: opacity 0.3s ease, background 0.3s ease;
    }
  }

  input[type=checkbox]:checked + label {
    .tmcCheckbox__icon {
      background: colors.grayAlabaster;
      border-color: colors.grayDust;

      &:after {
        background: colors.oceanBlue;
      }
    }
  }

  &.is-invalid,
  &.is-invalid:hover {
    input[type=checkbox]:checked + label {
      .tmcCheckbox__icon {
        background: colors.errorFadedRed;
        border-color: colors.errorRed;

        &:after {
          background: colors.errorRed;
        }
      }
    }
  }

  &.is-disabled,
  &.is-disabled:hover {
    input[type=checkbox]:checked + label {
      .tmcCheckbox__icon {
        background: colors.grayAlabaster;
        border-color: rgba(colors.grayDust, 0.5);

        &:after {
          opacity: 0.5;
          background: colors.oceanBlue;
        }
      }
    }
  }
}

.tmcCheckboxText {
  description2();

  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  padding-left: 2.15em;
  white-space: normal;
}
