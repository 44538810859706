
.formNotification {
  font('cancerfondensans-bold');
  font-size: 12px;
  color: #666;
  margin-top: 0.25em;
  position: absolute;
  z-index: 4;
  top: -0.85em;
  right: 0.5em;
  line-height: 1.1;
  padding: 2px 4px 3px;
  border-radius: 3px;

  &.is-invalid {
    background: colors.burntSienna;
    color: #fff;
  }
}
