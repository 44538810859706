
.checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  line-height: 1.3;

  a {
    type-link();
    font('cancerfondensans-regular');
  }

  &__box {
    position: absolute;
    top: 0.05em;
    left: 0;
    font-size: 1.2em;
    display: block;
    height: 1em;
    width: 1em;
    color: transparent;
    border: 1px solid #aaa;
    border-radius: 0.15em;
    background: #fff;

    &:after {
      content: ' ';
      position: absolute;
      top: 3px;
      left: 3px;
      bottom: 3px;
      right: 3px;
      background: transparent;
      transition: background 0.2s ease;
      border-radius: 2px;
    }

    .checkbox.is-checked & {
      &:after {
        background: colors.oceanBlue;
      }
    }

    .checkbox.is-required & {
      border: 2px solid colors.burntSienna;
    }
  }

  &__label {
    type-subtle();
    padding-left: 2.15em;
    display: inline-block;
    text-align: left;

    .checkbox.is-required & {
      color: colors.burntSienna;
    }
  }
}
