
.shareIcon {
  display: inline-block;
  font-size: 2.25em;
  height: 1.5em;
  width: 1.5em;
  line-height: 1.5;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  background: colors.oceanBlue;
  transition: color 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  &--small {
    font-size: 1.75em;
  }

  &--rightMargin {
    margin-right: 0.5rem;
  }

  &--facebook {
    background: colors.facebook;

    &:before {
      icon-facebook();
    }

    &:hover {
      background: lighten(colors.facebook, 20%);
      box-shadow: inset 0 0 0.25em darken(colors.facebook, 20%);
    }
  }

  &--twitter {
    background: colors.twitter;

    &:before {
      icon-twitter();
      left: 0.05em;
    }

    &:hover {
      background: lighten(colors.twitter, 20%);
      box-shadow: inset 0 0 0.25em darken(colors.twitter, 20%);
    }
  }

  &--instagram {
    background: colors.seance;

    &:before {
      icon-instagram();
      top: 0.02em;
    }

    &:hover {
      background: lighten(colors.seance, 20%);
      box-shadow: inset 0 0 0.25em darken(colors.seance, 20%);
    }
  }

  &--email {
    background-color: colors.oceanBlue;

    &:before {
      icon-mail();
      top: -0.025em;
      font-size: 0.75em;
      left: 0.025em;
    }

    &:hover {
      background: darken(colors.oceanBlue, 20%);
      box-shadow: inset 0 0 0.25em darken(colors.oceanBlue, 40%);
    }
  }

    &--print {
    background-color: colors.oceanBlue;

    &:before {
      icon-print();
      top: -0.025em;
      font-size: 0.75em;
      left: 0.025em;
    }

    &:hover {
      background: darken(colors.oceanBlue, 20%);
      box-shadow: inset 0 0 0.25em darken(colors.oceanBlue, 40%);
    }
  }

  &--gift {
    background-color: colors.oceanBlue;

    &:before {
      icon-heart();
      top: -0.05em;
      font-size: 0.75em;
      left: 0;
    }

    &:hover {
      background: darken(colors.oceanBlue, 20%);
      box-shadow: inset 0 0 0.25em darken(colors.oceanBlue, 40%);
    }
  }
}
