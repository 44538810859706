
tmcButton() {
  font-family: 'CancerfondenSans', sans-serif;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.675em 1em 0.725em 1em; //11px 16px 13px;
  line-height: 1.25;
  border-radius: 0.25rem;
  background-color: transparent;
  transition: color 0.3s ease, background 0.3s ease, border 0.3s ease;
  position: relative;

  &:focus {
    outline: 0;
  }

  &--wide {
    width: 100%;
  }

  &.is-disabled,
  &.is-disabled:hover {
    cursor: default;
  }

  &.is-loading,
  &.is-loading:hover {
    cursor: default;
    color: transparent !important;
    spinner(1.5em, 2px, 0.3s, rgba(#fff, 0.75), rgba(#fff, 0.25));
  }
}

.tmcButtonGreen {
  tmcButton();
  color: #fff;
  background-color: colors.successGreen;
  border-color: colors.successGreen;

  &:focus,
  &:hover {
    background-color: colors.successGreenHover;
    border-color: colors.successGreenHover;
  }

  &.is-disabled,
  &.is-disabled:hover {
    color: rgba(#fff, 0.5);
    background-color: colors.successGreenDisabled;
    border-color: colors.successGreenDisabled;
  }

  &.is-loading,
  &.is-loading:hover {
    background-color: colors.successGreen;
    border-color: colors.successGreen;
  }
}
