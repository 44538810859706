
.threeColumnInfoSection {
  &__title {
    type-h1();
  }

  &__preamble {
    font('cancerfondensans-light');
    font-size: 1.35em;
    line-height: 1.3;
    padding-top: 0.25em;
    max-width: 940px;
    padding-bottom: 1em;

    +mq(until: 'small') {
      font-size: 1.125em;
    }
  }

  &__description {
    p {
      margin-top: 0.5em;

      &:first-child {
        margin-top: 0;
      }
    }

    +mq(from: 'small', until: 'medium') {
      column-count: 2;
      column-gap: 2em;
    }

    +mq(from: 'medium') {
      column-count: 3;
      column-gap: 2em;
    }
  }
}
