
.richTextContent {
  h2 {
    type-h3();
    margin: 1.5em 0 0;
  }

  h3 {
    type-h4();
    margin: 1.2em 0 0;
  }

  p,
  li {
    margin: 0.5em 0 0;
  }

  ol,
  ul {
    padding: 0 0 0 1.5em;
  }

  ul {
    list-style: disc;
  }

  p,
  h3,
  figcaption,
  ol,
  ul {
    &:first-child {
      margin-top: 0;
    }
  }

  a {
    type-a();
    font('cancerfondensans-regular');
    
    &:focus, &:hover {
      color: colors.hoverBlue;
    }
  }

  b,
  strong {
    font('cancerfondensans-bold');
  }

  em {
    font-style: italic;
  }

  quote {
    display: block;
    font-weight: normal;
    font-size: 2em;
    text-align: center;
    max-width: 80%;
    margin: 1.5em auto;
  }

  blockquote {
    font('cancerfondensans-light');
    clear: both;
    position: relative;
    margin: 1.5rem 3rem;
    font-size: 1.2em;
    padding-left: 3.75em;

    +mq(until: 'medium') {
      margin: 1.5rem 0;
      font-size: 1.1em;
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0.15rem;
      left: 0;
      width: 2.5em;
      height: 1.5em;
      background: url('../../../images/quote.png') center center no-repeat;
      background-size: contain;

      +mq(from: 'medium') {
        font-size: 1.5em;
        left: -1.5rem;
      }
    }
  }

  blockquote + p {
    margin-top: -0.75em;

    & em {
      display: inline-block;
      font-family: 'CancerfondenSans', sans-serif;
      font-style: italic;
      font-size: 13px;
      padding: 0 0 0 4rem;

      +mq(from: 'medium') {
        font-size: 14px;
        padding-left: 7.5rem;
        padding-right: 3rem;
      }
    }
  }

  //
  //
  // Inline Image
  div.inlineImage {
    position: relative;
    margin: 2em 0;
    padding: 0;
    border: 0;
    font-size: 1em;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    br {
      display: none;
    }

    &__caption {
      type-subtle();
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1em;
      line-height: 1.2;
      color: #fff;
      background: rgba(#000, 0.75);
    }

    &--normal {
      clear: both;
    }

    &--wide {
      clear: both;

      +mq(from: 1140px) {
        margin-left: -140px;
        margin-right: -140px;
      }
    }

    &--right {
      clear: right;

      +mq(from: 'small') {
        width: 50%;
        max-width: 300px;
        float: right;
        margin: 0.75em 0 1.5em 1.25em;

        &:first-child {
          margin-top: 0;
        }
      }

      +mq(from: 'large') {
        margin-right: -140px;
      }
    }
  }

  //
  //
  // Inline video
  div.inlineVideo {
    position: relative;
    margin: 2em 0;
    padding: 0;
    border: 0;
    font-size: 1em;

    &__wrapper {
      position: relative;
      padding-bottom: 56%;
      width: 100%;
      height: 0;
      overflow: hidden;

      iframe,
      object,
      embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
    }

    br {
      display: none;
    }

    &__caption {
      type-subtle();
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1em;
      line-height: 1.2;
      color: #fff;
      background: rgba(#000, 0.75);
    }

    &--normal {
      clear: both;
    }

    &--wide {
      clear: both;

      +mq(from: 1140px) {
        margin-left: -140px;
        margin-right: -140px;
      }
    }

    &--right {
      clear: right;

      +mq(from: 'small') {
        width: 50%;
        max-width: 300px;
        float: right;
        margin: 0.75em 0 1.5em 1.25em;

        &:first-child {
          margin-top: 0;
        }
      }

      +mq(from: 'large') {
        margin-right: -140px;
      }
    }
  }

  //
  //
  // Legacy inline images with figure
  figure.inlineImage {
    position: relative;
    margin: 2em 0;
    padding: 0;
    border: 0;
    font-size: 1em;
    clear: right;

    +mq(from: 'small') {
      width: 50%;
      max-width: 300px;
      float: right;
      margin: 0.75em 0 1.5em 1.25em;

      &:first-child {
        margin-top: 0;
      }
    }

    +mq(from: 'large') {
      margin-right: -140px;
    }

    br {
      display: none;
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    figcaption {
      type-subtle();
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1em;
      line-height: 1.2;
      color: #fff;
      background: rgba(#000, 0.75);
    }
  }

  figure {
    margin: 2em 0;
    padding: 2em;
    border: 2px solid #ccc;
    font-size: 0.875em;
    clear: right;

    +mq(from: 'small') {
      width: 50%;
      max-width: 300px;
      padding: 1.25em 1.5em;
      float: right;
      margin: 2em 0 1.5em 1.25em;

      &:first-child {
        margin-top: 0;
      }
    }

    +mq(from: 'large') {
      margin-right: -140px;
    }

    .richTextContent--noOverflow {
      +mq(from: 'large') {
        margin-right: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    p,
    figcaption {
      &:first-child {
        margin-top: 0;
      }
    }

    h2,
    h3,
    p,
    figcaption,
    ol,
    ul {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
