
.heroNavigation {
  background-color: rgba(#000, 0.5);
  height: 5.5em;
  width: 100%;
  transition: background 0.3s ease, height 0.3s ease, font-size 0.3s ease;

  +mq(until: 'large') {
    height: 4em;
  }

  &.is-small {
    height: 4em;
  }

  &.is-sticky {
    position: fixed;
    z-index: 9;
    height: 50px;
    top: 0;
    background-color: rgba(#000, 0.8);
    font-size: 0.9em;
    left: 0;
  }

  &__main {
    content-main(false);
    height: 100%;
  }

  &__links {
    display: table;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    table-layout: fixed;
  }

  &__link {
    font('cancerfondensans-regular');
    line-height: 1.2;
    overflow: hidden;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid rgba(#ccc, 0.5);
    padding: 0 0.5em;
    text-decoration: none;
    color: #fff;
    text-shadow: none;
    transition: color 0.3s ease, background-color 0.3s ease;

    // Caret icon
    &:after {
      content: ' ';
      position: absolute;
      bottom: -1em;
      left: 50%;
      margin-left: 0.75em * -0.5;
      border: 0.75em solid transparent;
      width: 0;
      height: 0;
      transition: bottom 0.3s ease, border 0.3s ease;

      .hero-navigation.is-sticky & {
        bottom: auto;
        top: -1em;
        transition: top 0.3s ease, border 0.3s ease;
      }
    }

    +mq(from: 'medium') {
      font-size: 1.125em;
    }

    &:first-child {
      border-left: 1px solid rgba(#ccc, 0.5);
    }

    +mq(from: 'medium') {
      &:hover {
        background-color: colors.oceanBlue;

        &:after {
          border-bottom-color: #fff;
          bottom: 0;
        }

        .hero-navigation.is-sticky & {
          // Set caret to top position
          &:after {
            top: 0;
            bottom: auto;
            border-top-color: #fff;
            border-bottom-color: transparent;
          }
        }
      }
    }
  }
}
