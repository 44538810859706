
.searchField {
  position: relative;
  max-width: 400px;
  display: inline-block;
  width: 100%;

  &__input {
    normalize-input();

    font('cancerfondensans-regular');
    line-height: normal;
    cursor: auto;
    text-align: left;
    border: 2px solid #666;
    padding: 0.5em 1em;
    border-radius: 4em;
    width: 100%;
    background: #fff;
  }

  &__button {
    normalize-button();

    position: absolute;
    top: 0;
    right: 0.5em;
    border: 0;
    padding: 0.5em;

    &:before {
      icon-search();
      display: inline-block;
      top: 0.04em;
      color: #666;
      font-size: 1.4em;
    }
  }
}
