
.globalNewsFeed {
  clearfix();

  +mq(from: 'small') {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__item {
    +mq(until: 'small') {
      clearfix();
      margin-top: 0.75em;
      padding-top: 0.75em
      border-top: 1px solid #ddd;

      &:first-child {
        margin-top: 0;
        padding-top: 0
        border-top: 0;
      }
    }

    +mq(from: 'small') {
      align-self: stretch;
      position: relative;
      float: left;
      width: 28%;
      padding: 0 1.5em;
      border-left: 1px solid #ddd;

      &:first-child {
        width: 44%;
        border-left: 0;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    +mq(from: 'medium') {
      width: 25%;

      &:first-child {
        width: 50%;
      }
    }
  }
}



.globalNewsFeedItem {
  display: block;
  type-link(#4A4A4A);

  &__imageWrapper {
    +mq(until: 'small') {
      max-width: 120px;
    }

    +mq(until: 'medium') {
      float: right;
      width: 35%;
      max-width: 120px;
      margin-left: 1em;
    }

    +mq(from: 'medium') {
      float: left;
      width: 50%;
      margin-right: 1.5em;
    }
  }

  &__image {
    display: inline-block;
    width: 100%;
  }

  &__info {
    +mq(from: 'small') {
      padding-bottom: 3.5em;
    }

    +mq(from: 'medium') {
      .globalNewsFeed__item:first-child & {
        float: left;
        width: calc(50% - 1.5em);
      }
    }
  }

  &__category {
    font('cancerfondensans-bold');
    display: inline-block;
    margin-bottom: 0.75em;
    font-size: 0.875em;
    line-height: 1.2;
    padding: 0.25em 0.5em;
    color: #fff;
    background: colors.darkBlue;
  }

  &__title {
    font('cancerfondensans-bold');
    font-size: 1.25em;
    line-height: 1.2;
  }

  &__meta {
    font-size: 0.875em;
    line-height: 1.3;
    color: #888;
    margin: 0.5em 0;
  }

  &__preamble {
    font-size: 0.9375em;
  }

  &__link {
    +mq(until: 'small') {
      margin-top: 0.25em;
    }

    +mq(from: 'small') {
      position: absolute;
      bottom: 0;
      padding-right: 1em;
    }

    +mq(from: 'medium') {
      .globalNewsFeed__item:first-child & {
        left: calc(50% + 0.75em);
      }
    }
  }
}
