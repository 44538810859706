
.author {
  // Layout
  &__imageWrapper {
    display: inline-block;
    width: 20%;
    max-width: 160px;
    vertical-align: middle;
    padding-right: 1.25em;

    +mq(until: 'small') {
      padding-right: 1em;
    }

    &--small {
      max-width: 120px;
    }
  }

  &__info {
    display: inline-block;
    width: 80%;
    vertical-align: middle;
  }
  // Elements
  &__image {
    width: 100%;
    display: block;
    border-radius: 50%;
  }

  // Typography
  &__name {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 1.3;
    margin: 0 0 0.1em;

    +mq(from: 'small') {
      font-size: 1.4em;
    }
  }

  &__role {
    font-size: 0.875em;
    margin: 0;
    color: #888;

    +mq(from: 'small') {
      font-size: 1.125em;
    }
  }

  &__metaData {
    font-size: 0.875em;
    margin: 0;

    +mq(from: 'small') {
      font-size: 1.125em;
    }
  }

  &__link {
    type-link();
  }
}

.authorSmall {
  // Layout
  &__imageWrapper {
    display: inline-block;
    width: 5em;
    max-width: 60px;
    vertical-align: middle;
    margin-right: 1em;
  }

  &__info {
    display: inline-block;
    width: calc(100% - 6em);
    vertical-align: middle;
  }

  // Elements
  &__image {
    width: 100%;
    display: block;
    border-radius: 50%;
  }

  // Typography
  &__name {
    font-weight: normal;
    font-size: 1.375em;
    line-height: 1.3;
    margin: 0 0 0.1em;
  }

  &__role {
    margin: 0;
    line-height: 1.3;
    color: #888;
  }

  &__metaData {
    margin: 0;
    line-height: 1.3;
  }

  &__link {
    type-link();
  }
}

