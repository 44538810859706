
.editorialMetaItem {
  margin-top: 1.5em;

  &:first-child {
    margin-top: 0;
  }

  &__label {
    font('cancerfondensans-bold');
  }

  &__title {
    font-size: 1.2em;
  }

  &__byline {
    font-size: 0.8125em;
    color: #888;
    margin-top: 0.2em;
  }
}
