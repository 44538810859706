
//
// Error message
//
.tmcErrorMessage {
  font-size: 0.9375em;
  padding: 1em;
  color: colors.errorRed;
  background: colors.errorFadedRed;

  em, b {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }
}
