
icon() {
  font-family: 'IcoMoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
}

// Mixins
icon-print() {
  icon();
  content: "\e900";
}
icon-play-solid() {
  icon();
  content: "\e60a";
}
icon-search() {
  icon();
  content: "\e036";
}
icon-caret-left() {
  icon();
  content: "\f104";
}
icon-caret-right() {
  icon();
  content: "\f105";
}
icon-image() {
  icon();
  content: "\e010";
}
icon-play() {
  icon();
  top: -0.06em;
  content: "\e052";
}
icon-facebook() {
  icon();
  content: "\e601";
}
icon-twitter() {
  icon();
  content: "\e603";
}
icon-instagram() {
  icon();
  content: "\e600";
}
icon-close() {
  icon();
  content: "\e117";
}
icon-info() {
  content: "\e609";
  icon();
}
icon-heart() {
  icon();
  content: "\e024";
}
icon-menu() {
  icon();
  content: "\e120";
}
icon-check() {
  icon();
  content: "\e116";
}
icon-cross() {
  icon();
  content: "\e117";
}
icon-angle-down() {
  icon();
  content: "\f107";
}
icon-angle-up() {
  icon();
  content: "\f106";
}
icon-mail() {
  icon();
  content: "\e002";
}
icon-arrow-left() {
  icon();
  content: "\e094";
}
icon-cog() {
  icon();
  content: "\e023";
}
icon-paper-clip() {
  icon();
  content: "\e001";
}
icon-paper() {
  icon();
  content: "\e034";
}
icon-bank() {
  icon();
  content: "\e908";
}



// Classes using mixins
.icon-facebook:before {
  icon-facebook();
}
.icon-twitter:before {
  icon-twitter();
}
.icon-heart:before {
  icon-heart();
}
.icon-menu:before {
  icon-menu();
}
.icon-mail:before {
  icon-mail();
}
.icon-print:before {
  icon-print();
}


// Standard classes
.icon-paper-clip:before {
  icon-paper-clip();
}
.icon-search:before {
  icon-search();
}
.icon-image:before {
  icon-image();
}
.icon-youtube:before {
  icon();
  content: "\e604";
}
.icon-instagram:before {
  icon();
  content: "\e600";
}
.icon-google:before {
  icon();
  content: "\e602";
}
.icon-heart:before {
  icon();
  content: "\e024";
}
.icon-tag:before {
  icon();
  content: "\e085";
}
.icon-location:before {
  icon();
  content: "\e608";
}
.icon-paper:before {
  icon();
  content: "\e034";
}
.icon-heart:before {
  icon();
  content: "\e024";
}
.icon-speech-bubble:before {
  icon();
  content: "\e076";
}
