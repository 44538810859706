
.selectGroup {
  position: relative;
  width: 100%;

  &:before {
    icon-angle-down();
    position: absolute;
    font-size: 1.25em;
    top: 50%;
    right: 0.75em;
    z-index: 1;
    transform: translateY(-50%);
    pointer-events: none;

    &.is-disabled {
      color: #aaa;
    }
  }

  &__wrapper {
    input-group();
    padding-right: 2.5em;
    overflow: hidden;
    background-color: #fff;

    .selectGroup.is-disabled & {
      border-color: #ccc;
      background: #f4f4f4;
    }
  }

  &__select {
    ui-normalize-input();
    input-group-element();
    cursor: pointer;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: calc(100% + 2.5em);

    .selectGroup.is-disabled & {
      color: #aaa;
      cursor: default;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
