
.heroTitle {
  &--offset {
    +mq(from: 'small', until: 'medium') {
      margin-top: -0.5em;
    }
    +mq(from: 'medium', until: 'large') {
      margin-top: -0.5em;
    }
    +mq(from: 'large') {
      margin-top: -0.5em;
    }
  }

  &__title {
    line-height: 1.1;

    &--light {
      font(cancerfondensans-light);
    }

    &--normal {
      font(cancerfondensans-regular);
    }

    &--bold {
      font(cancerfondensans-bold);
    }

    &--small {
      +mq(until: 'small') {
        font-size: 1.5em;
      }

      +mq(from: 'small', until: 'medium') {
        font-size: 1.75em;
      }

      +mq(from: 'medium', until: 'large') {
        font-size: 2em;
      }

      +mq(from: 'large') {
        font-size: 2.25em;
      }
    }

    &--medium {
      +mq(until: 'small') {
        font-size: 1.75em;
      }

      +mq(from: 'small', until: 'medium') {
        font-size: 2em;
      }

      +mq(from: 'medium', until: 'large') {
        font-size: 2.5em;
      }

      +mq(from: 'large') {
        font-size: 3em;
      }
    }

    &--large {
      +mq(until: 'small') {
        font-size: 2em;
      }

      +mq(from: 'small', until: 'medium') {
        font-size: 2.75em;
      }

      +mq(from: 'medium', until: 'large') {
        font-size: 3.25em;
      }

      +mq(from: 'large') {
        font-size: 4em;
      }
    }
  }

  &__preamble {
    font('cancerfondensans-bold');
    line-height: 1.3;
    margin-top: 0.5em;
    display: inline-block;
    max-width: 740px;

    +mq(until: 'tiny') {
      font-size: 1em;
      line-height: 1.2;
    }

    &--default {
      font-size: 1.25em;

      +mq(from: 'large') {
        font-size: 1.35em;
      }

      +mq(until: 'small') {
        display: none;
      }
    }

    &--phone {
      font-size: 1em;

      +mq(from: 'small') {
        display: none;
      }
    }
  }

  &__button {
    font-size: 0.875em;
    margin-top: 1em;

    +mq(from: 'tiny') {
      margin-top: 1.5em;
      font-size: 1em;
    }

    &--large {
      font-size: 1em;

      +mq(from: 'tiny') {
        font-size: 1.2em;
      }

      +mq(from: 'medium') {
        font-size: 1.4em;
      }
    }
  }
}
