
.inputGroup {
  position: relative;

  &--inline {
    margin-bottom: 0;
  }

  &__input {
    input-group();
    background: #fff;

    .inputGroup--inline & {
      border: 0;
      border-radius: 0;
      border-left: 1px solid #aaa;
    }

    .inputGroup.is-invalid & {
      background: #fcf8e3;
      border: 1px solid #dca7a7;

      .inputGroup__field {
        color: #763a39;

        &:-webkit-autofill {
          -webkit-text-fill-color: #763a39;
          box-shadow: inset 0 0 0 100px #fcf8e3;
        }
      }
    }

    .inputGroup.is-valid & {
      border-color: #61d77a;
      background-color: #fdf8f1;
    }

    .inputGroup.is-disabled & {
      border-color: #ccc;
      background: #f4f4f4;

      .inputGroup__field {
        color: #aaa;

        &:-webkit-autofill {
          -webkit-text-fill-color: #aaa;
          box-shadow: inset 0 0 0 100px #f4f4f4;
        }
      }
    }
  }

  &__addon {
    input-group-element();
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 1%;
    line-height: 1;
    vertical-align: middle;
    text-align: center;

    &--before {
      border-right: 1px solid #aaa;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--after {
      border-left: 1px solid #aaa;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--tooltip {
      position: relative;
      cursor: pointer;
      color: #aaa;
      font-size: 1.5em;
      padding: 0.25em 0.4em;
      height: 2em;

      &:after {
        icon-info();
        top: 0.03em;
      }

      +mq(until: 'medium') {
        display: none;
      }
    }

    &--search {
      position: relative;
      cursor: pointer;
      color: #aaa;
      font-size: 1.5em;
      padding: 0.25em 0.4em;
      height: 2em;

      &:after {
        icon-search();
        top: 0.03em;
      }

      +mq(until: 'medium') {
        display: none;
      }
    }
  }

  &__tooltip {
    font-size: 1rem;
    position: absolute;
    z-index: 5;
    backface-visibility: hidden;
    left: 50%;
    top: 0.3em;
    opacity: 0;
    overflow: hidden;
    transition: top 0.3s ease, opacity 0.3s ease;

    .inputGroup__addon--tooltip:hover & {
      opacity: 1;
      top: 0.15em;
      overflow: visible;
    }
  }

  &__field {
    ui-normalize-input()
    input-group-element();
    line-height: 1.42857143;
    width: 100%;
    position: relative;
    z-index: 2;
    float: left;
    border-radius: 0.25em;

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 100px #fff;
    }
  }
}
