
.engagementItem {
  display: block;
  background: colors.oceanBlue;
  text-align: left;
  max-width: 320px;
  margin: 0 auto;
  overflow: hidden;

  +mq(until: 'tiny') {
    font-size: 0.6em;
  }

  &--fluid {
    max-width: 768px;
  }

  &__content {
    display: block;
    position: relative;
    padding-top: 65%;
    height: 0;
    color: #fff;

    .engagementItem--fluid & {
      +mq(until: 'medium') {
        font-size: 3.5vw;
      }

      +mq(from: 'medium') {
        font-size: 2.1em;
      }
    }
  }

  &__meta {
    vertical-wrapper();
    width: 100%;
    height: 5em;
    max-height: 7em;
    padding: 0 0.75em;
    color: colors.text;
    background: #f6fafb;
    border: 1px solid #eee;

    .engagementItem--fluid & {
      height: 22vw;
      padding: 0 3%;
    }
  }

  &__image {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center center;
    background-size: cover;

    .engagementItem--caption & {
      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5em;
        background: linear-gradient(transparent, rgba(#000, 0.8));
      }
    }
  }

  &__info {
    position: absolute;
    z-index: 3;
    top: 0.75em;
    left: 0.75em;
    width: calc(100% - 1.5em);
    height: calc(100% - 1.5em);
    vertical-wrapper();

    .engagementItem--fluid & {
      top: 4%;
      left: 4%;
      width: calc(100% - 8%);
      height: calc(100% - 8%);
    }

    .engagementItem--image & {
      text-shadow: 0 1px 0 rgba(#000, 0.6);
    }
  }

  &__date {
    font('cancerfondensans-bold');
    font-size: 0.75em;
    position: absolute;
    top: -0.25em;
    left: 0;
    width: 100%;
    text-align: center;
  }

  &__caption {
    font('cancerfondensans-bold');
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 0.875em;
    line-height: 1.2;

    &--medium {
      font-size: 1em;
    }

    &--large {
      font-size: 1.2em;
    }

    &--donation {
      font-size: 1.25em;

      em {
        font('cancerfondensans-bold');
        font-size: 1.5em;
        display: block;
      }
    }
  }

  &__text {
    vertical-content();
    width: 100%;
    text-align: center;
    line-height: 1.3;

    em {
      font('cancerfondensans-bold');
    }

    &--tiny {
      font-size: 0.95em;
      line-height: 1.5;

      +mq(until: 'small') {
        line-height: 1.2;
      }
    }

    &--small {
      font-size: 1.15em;
      line-height: 1.5;

      +mq(until: 'small') {
        line-height: 1.2;
      }
    }

    &--medium {
      font-size: 1.25em;
      line-height: 1.5;

      +mq(until: 'small') {
        line-height: 1.2;
      }
    }

    &--large {
      font-size: 1.65em;
      font('cancerfondensans-regular');
    }
  }

  &-enter {
    opacity: 0.01;
    transition: opacity 0.3s ease-in;

    &-active {
      opacity: 1;
    }
  }

  &-leave {
    opacity: 1;
    transition: opacity 0.3s ease-in;

    &-active {
      opacity: 0.01;
    }
  }
}


.engagementItemMeta {
  vertical-content();
  width: 100%

  &__content {
    vertical-wrapper();
    width: 100%;
  }

  &__info {
    vertical-content();
    width: 100%;

    &--avatar {
      width: calc(100% - 3.25em);
      padding-left: 0.75em;

      .engagementItem--fluid & {
        width: calc(100% - 6em);
      }
    }

    .engagementItem--fluid & {
      +mq(from: 'small', until: 'medium') {
        font-size: 1.15em;
      }
      +mq(from: 'medium') {
        font-size: 1.3em;
      }
    }
  }

  &__avatar {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background: #fff center center;
    background-size: cover;
    width: 3.25em;
    height: @width;
    border: 2px solid #fff;

    .engagementItem--fluid & {
      width: 16vw;
      height: 16vw;
      max-width: 5em;
      max-height: 5em;
    }
  }

  &__name {
    font('cancerfondensans-bold');
    font-size: 1.15em;
    line-height: 1.25;
    //margin-bottom: -0.25em;

    &--truncate {
      text-truncate();
    }
  }

  &__link {
    type-subtle();
    color: #666;
    display: inline-block;

    em {
      font('cancerfondensans-regular');
    }

    .engagementItem--twitter & {
      type-link(#fff, #fff);
    }
  }

  &__source {
    font('cancerfondensans-bold');
    text-transform: capitalize;
    color: colors.oceanBlue;

    .engagementItem--twitter & {
      color: #fff;
    }
  }
}

