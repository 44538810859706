
.editorialImage {
  &--wide {
    content-main(false);
  }

  &--normal {
    max-width: 920px;
    margin: 0 auto;
  }

  &--narrow {
    max-width: 696px;
    margin: 0 auto;
  }

  &--high {
    margin: 0 auto 1.5em;
    max-width: 370px;

    +mq(from: 'small') {
      float: right;
      margin-left: 1em;
      margin-right: -2em;
    }

    +mq(from: 'large') {
      max-width: 420px;
      margin-right: -244px;
    }
  }

  &__content {
    position: relative;
  }

  &__image {
    width: 100%;
    display: block;
  }

  &__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(#000, 0.75);
    padding: 1em;
    line-height: 1.2;
    color: #fff;
    type-subtle();
  }
}
