
.twoUp {
  clearfix();
  margin: 0 auto;

  &__item {
    layout-grid(2, 3em, 2.25em);

    .twoUp--stackSmall & {
      +mq(until: 'small') {
        layout-stack(1.5em);
      }
    }
  }
}

.threeUpGrid {
  clearfix();
  margin: 0 auto;

  &__item {
    layout-grid(3, 3em, 2.25em);

    .threeUpGrid--stackSmall & {
      +mq(until: 'small') {
        layout-stack(1.5em);
      }
    }
  }
}
