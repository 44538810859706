
//
// Spacer, magic mixin
//
spacer(margin-top = 1.5rem) {
  > * {
    margin-top: margin-top;

    &:first-child {
      margin-top: 0;
    }
  }

  > noscript:nth-child(1) + * {
    margin-top: 0;
  }
}

//
// Basic button
//
layout-stack(margin-top) {
  margin-top: margin-top;
  margin-left: 0;
  float: none;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
}

spacer(margin-top = 0.5rem, margin-top-first-child = 0) {
  > * {
    margin-top: margin-top;

    &:first-child {
      margin-top: margin-top-first-child;
    }
  }

  > noscript:nth-child(1) + * {
    margin-top: margin-top-first-child;
  }
}

//
// Global states
//
.is-hidden {
  display: none !important;
}

//
// Basic button
//
button(font-size = false) {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  margin-bottom: 0;
  background-color: transparent;
  background-image: none;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  text-shadow: none;
  line-height: normal;

  if font-size != false {
    font-size: font-size;
  }
}

//
// Layout for content in sections
//
content-wrapper() {
  padding-left: 1em;
  padding-right: 1em;

  &--smoke {
    background-color: #f4f4f4;
  }

  &--blackSqueeze {
    background-color: colors.blackSqueeze;
  }

  &--wispPink {
    background: colors.wispPink;
  }

  &--lipstick {
    background: colors.lipstick;
  }

  +mq(from: 'small') {
    padding-left: 2em;
    padding-right: 2em;
  }

  +mq(from: 'large') {
    padding-left: 3em;
    padding-right: 3em;
  }
}

content-main(padding = true) {
  max-width: 1200px;
  margin: 0 auto;

  if padding == true {
    padding: 2.25em 0;

    +mq(from: 'medium', until: 'large') {
      padding: 3em 0;
    }

    +mq(from: 'large') {
      padding: 4em 0;
    }
  }
}

//
// Layout for content in sections
//
vertical-wrapper() {
  display: inline-block;
  vertical-align: middle;

  &:after {
    content: ' ';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

vertical-content() {
  display: inline-block;
  vertical-align: middle;
}

//
// Spinner
//
@keyframes spinnerAnimation {
  0% {
    transform: rotateZ(-360deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}

spinner(size = 30px, border = 1px, speed = 0.3s, foreground = #666, background = #ccc) {
  &:after {
    animation: spinnerAnimation speed linear infinite;
    border-radius: 100%;
    border-bottom: border solid background;
    border-left: border solid foreground;
    border-right: border solid background;
    border-top: border solid foreground;
    display: block;
    height: size;
    width: size;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    content: ' ';
    margin-left: size*-0.5;
    margin-top: size*-0.5;
  }
}

spinner-size(size = 30px, border = 1px) {
  &:after {
    border-width: border;
    height: size;
    width: size;
    margin-left: size*-0.5;
    margin-top: size*-0.5;
  }
}

spinner-color(color = #666) {
  &:after {
    border-bottom-color: rgba(color, 20%);
    border-left-color: color;
    border-right-color: color;
    border-top-color: rgba(color, 20%);
  }
}

//
// Normalize
//
normalize-input() {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  background: transparent;
}

normalize-button() {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  margin-bottom: 0;
  background-color: transparent;
  background-image: none;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  font-size: 1em;
  line-height: normal;
  font-weight: normal;
}

//
// Temp
// ----------------------------------------------------------------------------------

box(boxColor = cyan) {
  border: 1px dotted boxColor;
  background: rgba(boxColor, 10%);
}

.box {
  box()
}

.dummy {
  box();
  padding: 4em;
  text-align: center;
}
