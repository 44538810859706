.featuredBrick {
  margin-left: auto;
  margin-right: auto;

  &--flex {
    display: flex;
    align-items: center;
    flex-direction: column;

    +mq(from: 'small') {
      flex-direction: row;
    }
  }

  &__figure {
    background: transparent;
    order: 1;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 1rem;

    +mq(until: 'small') {
      width: 100% !important;
    }

    +mq(from: 'small') {
      margin-bottom: 0;

      &--top {
        order: 1;
        margin-bottom: 1.5rem;
      }

      &--left {
        order: 1;
      }

      &--right {
        order: 2;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__text {
    order: 2;
    display: inline-block;
    vertical-align: middle;

    +mq(until: 'small') {
      width: 100% !important;
    }

    +mq(from: 'small') {
      &--left {
        padding-right: 3rem;
        order: 1;
      }

      &--right {
        padding-left: 3rem;
        order: 2;
      }
    }
  }

  &__intro {
    spacer(0.5rem);
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }

  &__heading {
    &--sizeLarge {
      heading1();
    }

    &--sizeMedium {
      heading2();
    }

    &--sizeSmall {
      heading3();
    }

    &--weightLight {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 300;
    }

    &--weightNormal {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 400;
    }
  }

  &__preamble {
    preamble2();
  }

  &__description {
    description1();
  }

  &__links {
    description1();
    margin-top: 1rem;
  }

  &__link {
    margin-top: 0.5rem;

    &--twoCol {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding-right: 0.5em;
    }
  }
}

