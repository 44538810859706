
.formCustomizeCertificate {
  clearfix();
  position: relative;

  &__preview {
    +mq(until: 'small') {
      max-width: 320px;
      margin: 0 auto;
    }

    +mq(from: 'small') {
      float: right;
      width: 45%;
    }
  }

  &__settings {
    +mq(until: 'small') {
      margin-top: 2em;
      text-align: center;
    }

    +mq(from: 'small') {
      float: left;
      width: 55%;
      padding-right: 2em;
    }
  }
}


.formCertificatePreview {
  max-width: 290px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 1px 3px rgba(#000, 0.3);
  font-size: 0.4em;
  text-align: center;

  &__placeholder {
    width: 100%;
    display: block;
  }

  &__header {
    position: absolute;
    top: 35%;
    left: 0;
    width: 100%;
  }

  &__image {
    position: absolute;
    top: 50%;
    top: 3em;
    left: 50%;
    width: 18em;
    height: 18em;
    margin-left: -9em;
    background: center center;
    background-size: cover;
  }

  &__content {
    position: absolute;
    top: 50%;
    bottom: 4.5em;
    left: 0;
    width: 100%;
    overflow: hidden;

    &--tight {
      top: 40%;
    }
  }

  &__title {
    font-size: 1.2em;
    margin: 0 10%;
  }

  &__horored {
    font-size: 1.6em;
    margin: 0 10%;
  }

  &__subtitle {
    margin: 0 10%;
    font-size: 1.3em;
  }

  &__honoredBy {
    margin: 0.4em 10% 0;
    font-size: 1.4em;
    line-height: 1.4;
    overflow: hidden;

    &--inline {
      margin-top: 0.8em;
      font-size: 1.3em;
    }
  }

  &__message {
    margin: 2.5em 28% 0;
    overflow: hidden;
  }

  &__button {
    display: none;
  }
}
