
.section {
  content-wrapper();

  &--full {
    padding-left: 0;
    padding-right: 0;
  }

  &__main {
    content-main();
    position: relative;

    .section--narrow & {
      margin-left: auto;
      margin-right: auto;
      max-width: 740px;
    }

    .section--limited & {
      margin-left: auto;
      margin-right: auto;
      max-width: 920px;
    }

    .section--full & {
      max-width: none;
    }

    .section--slim & {
      padding-top: 0;
      padding-bottom: 0;
    }

    .section--slimTop & {
      padding-top: 0;
    }

    .section--slimBottom & {
      padding-bottom: 0;
    }

    .section--borderTop & {
      border-top: 1px solid #ddd;
    }

    .section--borderBottom & {
      border-bottom: 1px solid #ddd;
    }

    .section--borderTopFaded &,
    .section--borderTopFadedShadow & {
      &:before {
        content: ' ';
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        background: url('../../images/hr-faded-top.png') center top no-repeat;

        +mq(until: 'small') {
          background-size: 100% 60px;
        }
      }

      & > * {
        z-index: 2;
      }
    }

    .section--borderTopFaded & {
      &:before {
        height: 1px;
      }
    }

    .section--borderTopFadedShadow & {
      &:before {
        height: 60px;
      }
    }

    .section--borderBottomFaded &,
    .section--borderBottomFadedShadow & {
      &:after {
        content: ' ';
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        background: url('../../images/hr-faded-bottom.png') center bottom no-repeat;

        +mq(until: 'small') {
          background-size: 100% 60px;
        }
      }

      & > * {
        z-index: 2;
      }
    }

    .section--borderBottomFaded & {
      &:after {
        height: 1px;
      }
    }

    .section--borderBottomFadedShadow & {
      &:after {
        height: 60px;
      }
    }
  }

  &__content {
    .section--narrow & {
      margin-left: auto;
      margin-right: auto;
      //max-width: 520px;
      max-width: 640px;
    }
  }

  &__footer {
    border-top: 1px solid #ddd;
    margin-top: 1.5em;
    padding-top: 1.5em;
  }

  // Typography
  &__title {
    type-h1();

    .section--center & {
      text-align: center;
    }
  }

  &__subtitle {
    type-h2();

    .section--center & {
      text-align: center;
    }
  }

  &__preamble {
    type-preamble();
    margin-top: 0.5em;

    &:first-child {
      margin-top: 0;
    }

    .section--center & {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 740px;
    }
  }

  &__form,
  &__search {
    margin-top: 2em;

    .section--narrow & {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }
  }
}
