
.formError {
  padding: 0.75em 1.25em;
  border-radius: 3px;
  background: colors.burntSienna;
  color: #fff;

  &__text {
    type-subtle();
    margin-top: 0.5em;
    font('cancerfondensans-bold');

    &:first-child {
      margin-top: 0;
    }

    a {
      type-link(#fff, #fff);
    }
  }
}
