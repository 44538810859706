
.formLabel {
  font('cancerfondensans-bold');
  font-size: 12px;
  color: #888;
  margin-top: 0.25em;
  position: absolute;
  z-index: 3;
  top: -0.95em;
  left: 0.75em;
  line-height: 1.1;
  padding: 2px 4px 3px;
  border-radius: 3px;
  background: #fff;
}
