
.imageUpload {
  position: relative;

  &__preview {
    position: relative;
    width: 100%;
    background: #eee center center no-repeat;
    background-size: cover;
    padding-top: 70%;

    .imageUpload--avatar & {
      padding-top: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &__spinner {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    font-size: 2em;
    height: 1em;
    width: 1em;
    margin: -0.5em 0 0 -0.5em;
    border: 2px solid red;
    border-radius: 50%;
  }

  &__button {
    position: absolute;
    z-index: 1;
    bottom: -0.75em;
    z-index: 2;
    width: 100%;
    text-align: center;

    .imageUpload--avatar & {
      .button {
        min-width: 0;
      }
    }

    .button {
      background: #fff;
    }

    .imageUpload:hover & {
      .button {
        background: colors.doveGray;
        color: #fff;
      }
    }
  }

  &__input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__iframe {
    display: none;
  }
}
