
.superHero {
  position: relative;
  z-index: 4;
  text-align: center;

  &--9by4 {
    height: round(0.444444 * 375px);

    +mq(375) {
      height: 0;
      padding-top: 44.4444%;
    }

    +mq('medium') {
      height: round(0.444444 * 992px);
      padding-top: 0;
    }
  }

  &--5by3 {
    height: round(0.60 * 375px);

    +mq(375) {
      height: 0;
      padding-top: 60%;
    }

    +mq('medium') {
      height: round(0.60 * 992px);
      padding-top: 0;
    }
  }

  &__main {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    ^[0]--overlay &,
    ^[0]--gradientOverlay & {
      &:after {
        content: ' ';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    ^[0]--overlay & {
      &:after {
        background: rgba(#000, 0.4);
      }
    }

    ^[0]--gradientOverlay & {
      &:after {
        background: linear-gradient(rgba(#000, 0) 40%, rgba(#000, 0.8));
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: middle;
    max-width: 480px;
    width: 98%;
    padding: 1.25em 0.5em;

    +mq('tiny') {
      padding: 1.25em 1em;
    }

    +mq('small') {
      padding: 2.5em 1em;
    }

    +mq('medium') {
      padding: 3em 0;
    }
  }

  &__backdrop {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__media {
    height: 100%;

    &--small {
      +mq('small') {
        display: none;
      }
    }

    &--medium {
      display: none;

      +mq('small', until: 'medium') {
        display: block;
      }
    }

    &--large {
      display: none;

      +mq('medium') {
        display: block;
      }
    }
  }

  &__image {
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;

    &--small {
      background-size: cover;
    }

    &--medium {
      background-size: cover;
    }
  }
}
