
.hero {
  position: relative;
  height: 200px;
  z-index: 4;

  .is-overlay & {
    z-index: 12;
  }

  // Sizing
  +mq(from: 'small', until: 'medium') {
    height: 400px;
  }

  +mq(from: 'medium', until: 1600px) {
    padding-top: 31.5%;
    height: 0;
  }

  +mq(from: 1600px) {
    height: 500px;
  }

  // Positioning
  &__background {
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    +mq(from: 'medium', until: 1600px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .hero--overlay & {
      &:after {
        content: ' ';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.4);
      }
    }
  }

  &__main {
    content-wrapper();
    padding-right: 0.5em;
    padding-right: 0.5em;
    text-align: center;
    height: 100%;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  &__info {
    content-main(0);
    max-width: 980px;
    display: inline-block;
    vertical-align: middle;
    width: 95%;
    margin: 0 auto;

    .hero--overlay & {
      position: relative;
      z-index: 2;
    }

    color: #fff;
    text-shadow: 0 1px 0 rgba(#000, 0.4);

    &--darkText {
      color: #333;
      text-shadow: 0 1px 0 rgba(#fff, 0.4);
    }
  }

  &__content {
    padding: 3em 0;
    max-width: 740px;
    display: inline-block;

    +mq(until: 'small') {
      padding: 1em 0;
    }

    &--hasNavigation {
      padding: 2em 0 5.5em;

      +mq(until: 'small') {
        padding: 1em 0;
      }
    }
  }

  &__navigation {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;

    +mq(until: 'small') {
      display: none;
    }
  }

  &__label {
    font-size: 4em;
    color: #fff;
  }
}
