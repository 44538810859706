
tmcFormItem($width, $align = top) {
  if $width < 100% {
    vertical-align: $align;
    display: inline-block;
    width: "calc(%s - %s)" % ($width 1rem);

    +mq('small') {
      width: "calc(%s - %s)" % ($width 1.125rem);
    }
  }
}

.tmcForm {
  clearfix();
  width: calc(100% + 1rem);
  margin-top: 1rem * -1;
  margin-right: 1rem * -1;

  +mq('small') {
    width: calc(100% + 1.125rem);
    margin-top: 1.125rem * -1;
    margin-right: 1.125rem * -1;
  }

  &__item {
    margin-top: 1rem;
    margin-right: 1rem;

    +mq('small') {
      margin-top: 1.125rem;
      margin-right: 1.125rem;
    }

    &--slimTop {
      margin-top: 0.5rem;
    }

    &--33 {
      tmcFormItem(33.33%);
    }

    &--50 {
      tmcFormItem(50%);
    }

    &--66 {
      tmcFormItem(66.66%);
    }

    &--stackSmall {
      +mq(until: 'small') {
        display: block;
        width: auto;
      }
    }
  }
}
