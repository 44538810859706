
.feedback {
  clearfix();
  padding: 0.75em;
  background: #fcf8e3;
  border: 1px solid #c9a29a;
  color: #926868;
  border-radius: 0.25em;
  margin: 0 auto;
  max-width: 640px;

  &--info {
    background: colors.smoke;
    border: 1px solid #ddd;
    color: #333;
  }

  &--noMargin {
    margin: 0;
  }

  &__grid {
    vertical-wrapper();
  }

  &__col {
    vertical-content();

    &--icon {
      position: relative;
      width: 2.25em;
      height: 2.25em;
      margin-right: 1em;
    }

    &--spinner {
      spinner(2em, 2px, 0.3s, #888, #ccc);
    }

    &--text {
      width: calc(100% - 4em);
    }
  }

  &--narrow {
    max-width: none;
    display: inline-block;
  }

  &__title {
    font('cancerfondensans-bold');
  }

  &__text {
    type-subtle();
    margin-top: 0.5em;
    font('cancerfondensans-regular');

    &:first-child {
      margin-top: 0;
    }
  }

  &__link {
    font('cancerfondensans-bold');
  }
}
