clearfix() {
  &:after, &:before {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

percentage(value) {
  return floor(((value) * 100)%, 3);
}

text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

main-content(max-width = 1268px, padding = 1.5rem) {
  padding-left: padding;
  padding-right: padding;
  width: 100%;
  max-width: max-width;
  margin: 0 auto;
}

layout-grid(columns = 4, gutter = 2em, margin-top = false) {
  column-width = 100% / columns;
  gutter-width = (gutter * (columns - 1)) / columns;
  gutter-width-rounded = ceil(gutter-width, 2);

  if (gutter-width != gutter-width-rounded) {
    warn("Rounding errors in layout-grid, look for selector: " + selector());
  }

  width: "calc(%s - %s)" % (column-width gutter-width-rounded);

  float: left;
  margin-left: gutter;

  &:nth-child({columns}n+1) {
    margin-left: 0;
    clear: both;
  }

  if margin-top {
    margin-top: margin-top;

    for num in (1..columns) {
      &:nth-child({num}) {
        margin-top: 0;
      }
    }
  }
}

layout-column(column-span = 3, gutter = 1.5em, total-columns = 12) {

  columns = total-columns/column-span;
  column-width = (100%/total-columns) * column-span;
  gutter-width = (gutter * (columns - 1)) /columns;

  width: "calc(%s - %s)" % (column-width gutter-width);

  float: left;
  margin-left: gutter;

  &:first-child {
    margin-left: 0;
  }
}
