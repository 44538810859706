
.textareaGroup {
  input-group();
  position: relative;

  &__textarea {
    ui-normalize-input()
    input-group-element();
    resize: none;
    line-height: 1.42857143;
    width: 100%;
    position: relative;
    z-index: 2;
    float: left;
    min-height: 120px;
    padding-top: 0.8em;
    white-space: pre-wrap !important;
    background: #fff;
    border-radius: 0.25em;

    &--tall {
      min-height: 240px;
    }
  }
}
