
.jumbotron {
  spacer(0.25rem);

  +mq('small') {
    spacer(0.5rem);
  }

  &--textWhite {
    color: #fff;
    text-shadow: 0 1px 0 rgba(#000, 0.4);
  }

  &--textBlack {
    color: colors.text;
    text-shadow: 0 1px 0 rgba(#fff, 0.4);
  }

  &__title {
    &--sizeLarge {
      heading1();
    }

    &--sizeMedium {
      heading2();
    }

    &--sizeSmall {
      heading3();
    }

    &--weightLight {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 300;
    }

    &--weightNormal {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 400;
    }
  }

  &__preambleContainer { }

  &__preamble {
    preamble2('bold');
    max-width: none;

    &--default {
      display: none;

      +mq('small') {
        display: block;
      }
    }

    &--phone {
      line-height: 1.2;

      +mq('small') {
        display: none;
      }
    }
  }

  &__descriptionContainer {
    +mq(until: 'small') {

      > *:nth-child(n+2) {
        display: none;
      }
    }
  }

  &__description {
    line-height: 1.2;
    font-size: 0.875em;

    +mq('small') {
      line-height: 1.5;
      font-size: 0.9375em;

      br {
        display: none;
      }
    }
  }

  &__links {
    text-shadow: none;
    font-size: 0.75em;
    padding-top: 0.5rem;

    +mq('small') {
      padding-top: 0.75rem;
      font-size: 0.9375em;
    }
  }
}
