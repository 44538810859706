
.heroVideo {
  &__content {
    vertical-wrapper();
    cursor: pointer;
    color: #fff;
    text-shadow: 0 1px 0 rgba(#000, 0.4);

    &--black {
      color: colors.text;
      text-shadow: 0 1px 0 rgba(#fff, 0.4);
    }
  }

  &__col {
    vertical-content();
    padding: 0.5em 1.5em;

    &:first-child {
      text-align: right;
      border-right: 1px solid #fff;
    }
  }

  &__title {
    type-h1();
    font('cancerfondensans-regular');
    line-height: 1.2;

    +mq(until: 'small') {
      display: none;
    }
  }

  &__preamble {
    font('cancerfondensans-bold');
    font-size: 1.35em;
    line-height: 1.3;
    margin: 0.25em auto 0;
    max-width: 740px;

    +mq(until: 'large') {
      max-width: 640px;
    }

    &--long {
      +mq(until: 'small') {
        display: none;
      }
    }

    &--short {
      +mq(from: 'small') {
        display: none;
      }
    }
  }

  &__button {
    display: inline-block;
    font-size: 4em;
    line-height: 1;
    width: 1em;
    height: @width;

    &:before {
      icon-play-solid()
      top: 0;
    }

    +mq(from: 'large') {
      font-size: 5em;
    }
  }
}
