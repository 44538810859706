
.editorialVideo {
  &--wide {
    content-main(false);
  }

  &--normal {
    max-width: 920px;
    margin: 0 auto;
  }

  &--narrow {
    max-width: 696px;
    margin: 0 auto;
  }

  &__content {
    position: relative;
    padding-top: 56.25%;
  }

  &__iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    border: 0;
  }
}
