
ui-normalize-input() {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  background: transparent;
}

input-group() {
  position: relative;
  display: table;
  border-collapse: separate;
  border: 1px solid #aaa;
  border-radius: 0.25em;
  width: 100%;
  transition: background 0.3s ease, border 0.3s ease;
}

input-group-element() {
  font-size: 1em;
  padding: 0 .75em;
  height: 3em;
  display: table-cell;
  white-space: nowrap;
  vertical-align: middle;

  &--small {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: 2.5em;
  }
}
