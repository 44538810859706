
font(family) {
  if family == 'cancerfondensans-light' {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 300;
  }
  
  if family == 'cancerfondensans-lightitalic' {
    font-family: 'CancerfondenSans', sans-serif;
    font-style: italic;
    font-weight: 300;
  }
  
  if family == 'cancerfondensans-regular' {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 400;
  }
  
  if family == 'cancerfondensans-regularitalic' {
    font-family: 'CancerfondenSans', sans-serif;
    font-style: italic;
    font-weight: 400;
  }
  
  if family == 'cancerfondensans-bold' {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }
  
  if family == 'cancerfondensans-extrabold' {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 800;
  }
  
  if family == 'opensans-regular' {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

  if family == 'opensans-light' {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  if family == 'opensans-medium' {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  if family == 'colfax-regular' {
    font-family: 'Colfax', sans-serif;
    font-weight: 400;
  }

  if family == 'colfax-light' {
    font-family: 'Colfax', sans-serif;
    font-weight: 300;
  }

  if family == 'colfax-medium' {
    font-family: 'Colfax', sans-serif;
    font-weight: 600;
  }
}

type-p() {
  font-size: 1rem;
  
  +mq(from: 'small') {
    font-size: 1.125rem;
  }
}

type-subtle() {
  font-size: 0.875em;
}

type-preamble() {
  font('cancerfondensans-bold');
  font-size: 1rem;
  line-height: 1.5;

  +mq(from: 'small') {
    font-size: 1.125rem;
  }
}

type-h1() {
  font('cancerfondensans-bold');
  font-size: 1.625rem;
  line-height: 1.230769231;

  +mq(from: 'small') {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}

type-h2() {
  font('cancerfondensans-bold');
  font-size: 1.375rem;
  line-height: 1.227272727;

  +mq(from: 'small') {
    font-size: 2rem;
    line-height: 1.15625;
  }
}

type-h3() {
  font('cancerfondensans-bold');
  font-size: 1.125rem;
  line-height: 1.2222222222;

  +mq(from: 'small') {
    font-size: 1.375rem;
    line-height: 1.227272727;
  }
}

type-h4() {
  font('cancerfondensans-bold');
  font-size: 1rem;
  line-height: 1.5;

  +mq(from: 'small') {
    font-size: 1.125rem;
  }
}

type-h5() {
  font('cancerfondensans-bold');
  font-size: 1rem;
  line-height: 1.5;

  +mq(from: 'small') {
    font-size: 1.125rem;
  }
}

type-link(color = colors.darkBlue, hover = colors.hoverBlue) {
  cursor: pointer;
  text-decoration: none;
  color: color;
  transition: s('color 0.3s ease');

  &:hover {
    color: hover;
  }
}

type-boldLink(color = colors.darkBlue, hover = colors.hoverBlue) {
  type-link(color, hover);
  font('cancerfondensans-bold');
}

type-arrowLink(color = colors.darkBlue, hover = colors.hoverBlue) {
  position: relative;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  margin-top: 0.5rem;
  padding-right: 1rem;
  color: color;

  &:hover {
    color: hover;
  }

  &::after {
    icon-caret-right();
    position: absolute;
    display: inline-block;
    padding-left: 0.375rem;
    font-size: 1.555555556em;
    line-height: 1rem;
    text-align: center;
    transition: color 0.3s ease-in-out;
    color: color;
  }

  &:hover::after {
    color: hover;
  }
}