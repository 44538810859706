
.tmcDonationForm {
  &__fields {
    max-width: 480px;
    margin: 2em auto 0;

    +mq('small') {
      margin-top: 3em;
    }

    &--slimTop {
      margin-top: 0;

      +mq('small') {
        margin-top: 0
      }
    }
  }

  &__submit {
    max-width: 480px;
    margin: 2em auto 0;

    +mq('small') {
      margin-top: 3em;
    }
  }
}
