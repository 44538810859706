
.videoOverlay {
  content-wrapper();
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;

  &.is-visible {
    display: block;
  }

  &__backdrop {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.9);
  }

  &__close {
    position: absolute;
    z-index: 12;
    font-size: 3em;
    top: 0.5em;
    right: 0.5em;
    color: #fff;

    &:before {
      icon-close();
    }
  }

  &__main {
    content-main();
    vertical-wrapper();
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 11;
  }

  &__content {
    vertical-content();
    width: 100%;
    max-width: 1080px;
  }
}
