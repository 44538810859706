
.ui-spacer {
  spacer(1.5rem);

  &--text {
    spacer(0.75rem);
  }
}

.ui-nobreak {
  white-space: nowrap;
}

.ui-center,
.ui-textCenter {
  text-align: center;
}

.ui-right {
  text-align: right;
}

.ui-left {
  text-align: left;
}

.ui-floatLeft {
  float: left;
}

.ui-floatRight {
  float: right;
}

.ui-clearfix {
  clearfix();
}

.ui-truncate {
  text-truncate();
}

.ui-smoke {
  background-color: colors.smoke;
}

.ui-bold,
.ui-strong {
  font('cancerfondensans-bold');
}

.ui-hidden-until-small {
  +mq(until: 'small') {
    display: none;
  }
}

.ui-hidden-from-small {
  +mq(from: 'small') {
    display: none;
  }
}

.ui-videoPlayer {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ui-imageUpload {
  input {
    position: absolute;
    top: 0;
    left: -200px;
    height: 100%;
    width: calc(100% + 200px);
    opacity: 0;
    cursor: pointer;
  }

  &.is-loading input {
    cursor: default;
  }
}

.ui-arrowLink {
  type-arrowLink();
}

.ui-blackLink {
  color: #000;
}

.ui-paperclip {
  type-link(colors.oceanBlue, darken(colors.oceanBlue, 20%));
  font('cancerfondensans-bold');
  position: relative;
  display: inline-block;
  line-height: 1.1;
  padding-left: 1.5em;

  &:before {
    icon-paper-clip();
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 1em;
    height: 1em;
    color: colors.oceanBlue;
    text-align: center;
    line-height: 0.9;
  }
}

.ui-arrowDownLink {
  type-arrowLink();

  &:before {
    transform: rotate(90deg);
  }
}

.ui-link {
  type-link();
  font('cancerfondensans-bold');
}
