
.threeUpArticleFeed {
  padding-top: 2em;

  &--slimTop {
    padding-top: 0;
  }

  &__list {
    clearfix();
    text-align: left;
  }

  &__item {
    clearfix();
    display: block;

    +mq(until: 'small') {
      layout-stack(1.5em);
    }

    +mq(from: 'small', until: 'medium') {
      layout-grid(3, 1.8em, 3em);
    }

    +mq(from: 'medium') {
      layout-grid(3, 3em, 4em);
    }
  }

  &__button {
    padding-top: 1.5em;

    +mq(from: 'medium') {
      padding-top: 3em;
    }
  }
}
