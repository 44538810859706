
.editorialItem {
  position: relative;
  padding: 0 0 4em;
  margin-top: 2em;

  +mq(until: 'medium') {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
    padding-bottom: 2.25em;
  }

  &__meta {
    display: inline-block;
    vertical-align: top;
    order: 2;
    padding: 1.5em 0 0 0;
    text-align: left;

    +mq(from: 'medium') {
      border-right: 1px solid #ccc;
      width: 220px;
      padding: 0 2em 2em 0;
      position: absolute;
      text-align: right;
    }
  }

  &__parentLink {
    font('cancerfondensans-bold');
    background: rgba(colors.oceanBlue, 0.15);
    display: block;
    padding: 0.2em 0.5em;
    transition: color 0.3s ease, background 0.3s ease;
    margin-bottom: 1.5em;

    &:hover {
      background: rgba(colors.oceanBlue, 0.3);
      color: darken(colors.oceanBlue, 20%);
    }

    +mq(until: 'medium') {
      display: inline-block;
    }
  }

  &__share {
    margin-bottom: 1.5em;

    +mq(until: 'medium') {
      display: none;
    }
  }

  &__metaItems {
    +mq(from: 'small') {
      margin-bottom: 1.5em;
    }
  }

  &__productButton {
    float: right;
    padding: 0 0 2em 2.5em;
  }

  &__body {
    display: inline-block;
    vertical-align: top;
    order: 1;
    width: 100%;

    +mq(until: 'medium') {
      padding-bottom: 1.5em;
      border-bottom: 1px solid #ccc;
    }

    +mq(from: 'medium') {
      width: 100%;
      min-height: 240px;
      padding: 0 0 0 calc(220px + 2em);
    }

    +mq(from: 'large') {
      padding: 0 calc(220px + 2em);
    }
  }

  &__title {
    type-h1();
    margin: 0.5em 0 0.25em 0;

    +mq(from: 'medium') {
      margin-top: 0;
    }
  }

  &__preamble {
    type-preamble();
    margin: 0 0 1.5em 0;
  }

  &__author {
    margin-top: 2em;
    clear: both;
  }

  &__relatedBox {
    padding-top: 2em;
  }
}

