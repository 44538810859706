
button-color-outline(color, background) {
  border-color: color;
  color: color;
  background: background;

  &:hover {
    background: color;
    color: background;

    &.is-loading {
      spinner-color(background);
      color: transparent;
    }
  }

  &.is-loading {
    spinner-color(color);
    color: transparent;
  }

  &.is-active {
    background: color;
    color: background;
  }

  &.is-disabled {
    border-color: lighten(desaturate(color, 25%), 50%);
    color: rgba(color, 0.6);
    background: lighten(desaturate(background, 25%), 50%);
    cursor: default;
  }
}

button-color-solid(color, background) {
  color: color;
  background: background;

  &:hover {
    background: color;
    color: background;
    border-color: background;

    &.is-loading {
      spinner-color(background);
      color: transparent;
    }
  }

  &.is-loading {
    spinner-color(color);
    color: transparent;
  }

  &.is-disabled {
    border-color: lighten(desaturate(color, 25%), 50%);
    color: rgba(color, 0.6);
    background: lighten(desaturate(background, 25%), 50%);
    cursor: default;
  }
}

.button--select {
  ui-normalize-input();
  -webkit-appearance: none;
}

.button {
  button(1em);
  font('cancerfondensans-bold');
  position: relative;
  border: 2px solid transparent;
  padding: 0.5625rem 1.5rem;
  line-height: 1.375;
  border-radius: 4em;
  transition: color 0.3s ease, border 0.3s ease, background 0.3s ease;
  min-width: 11em;
  overflow: hidden;

  &--wide {
    width: 100%;
    min-width: 0;
  }

  &--wideOnSmall {
    +mq(until: 'small') {
      width: 100%;
      min-width: 0;
    }
  }

  &--large {
    font-size: 1.1em;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }

  &--square {
    border-radius: 0.33em;
  }

  &.is-loading {
    spinner(1.5em, 2px);
    color: transparent !important;
  }

  // Outline
  &--ocean {
    button-color-outline(colors.darkBlue, #fff);
  }

  &--dove {
    button-color-outline(#666, #fff);
  }

  &--white {
    button-color-outline(#fff, colors.darkBlue);
  }

  &--seance {
    button-color-outline(colors.seance, #fff);
  }

  &--black {
    button-color-outline(#000, #fff);
  }

  // Solid
  &--solid-ocean {
    button-color-solid(#fff, colors.darkBlue);
  }

  &--solid-dove {
    button-color-solid(#fff, #666);
  }

  &--solid-seance {
    button-color-solid(#fff, colors.seance);
  }

  &--solid-white {
    button-color-solid(colors.darkBlue, #fff);
  }

  &--solid-aqua {
    button-color-solid(#fff, colors.aqua);
  }

  &--solid-set {
    button-color-solid(colors.lipstick, colors.thought);
    box-shadow:  0 0 15px colors.chantilly;
    min-width: 0;
    padding: 1em 1.2em;
  }

  &--solid-lipstick {
    button-color-solid(#fff, colors.lipstick);
    min-width: 0;
    padding: 1em 1.2em;
  }

  // Facebook
  &--facebook {
    background: colors.facebook;
    color: #fff;

    &:hover {
      background: transparent;
      color: colors.facebook;
      border-color: colors.facebook;
    }
  }
}
