
.formLayout {
  clearfix();
  position: relative;

  &--narrow {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    float: left;
    width: 100%;
    margin-top: 1.5em;

    &:first-child {
      margin-top: 0;
    }

    // Special modifiers
    &--address {
      margin-top: 0;
    }

    // Common modifiers
    &--slim {
      +mq(from: 'tiny') {
        margin-top: 0;
      }
    }

    &--creditCard {
      width: calc(50% - 1em);
      margin-right: 1.5em;
    }

    &--expiry {
      width: calc(25% - 1em);
      margin-right: 1.5em;
    }

    &--code {
      width: calc(25% - 1em);
    }

    &--twoCol1 {
      width: calc(50% - 0.75em);
      margin-right: 1.5em;
    }

    &--twoCol2 {
      width: calc(50% - 0.75em);
    }

    &--stack {
      +mq(until: 'tiny') {
        width: 100%;
        margin-right: 0;
      }
    }

    // Fluid width
    &--fluid {
      width: auto;
      margin-right: 1.5em;
    }

    // Fluid width
    &--checkbox {
      margin-top: 0.75em;
    }

    &--accept {
      text-align: left;
    }

    // Info
    &--info {
      position: relative;
      font-size: 13px;
      line-height: 1.3;
      color: #888;
      padding-left: 2.1em;
      margin-top: 1em;

      &:before {
        icon-info();
        position: absolute;
        left: 0;
        top: -0.15em;
        font-size: 1.6em;
        color: #aaa;
      }
    }

    // Annotation
    &--annotation {
      position: relative;
      font-size: 13px;
      line-height: 1.3;
      color: #888;
      margin-top: 1em;
    }

    // Amount
    &--user-defined-amount {
      +mq(from: 'tiny') {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(100%);
        width: 20%;
      }
    }

    // First & last name
    &--first-name {
      +mq(from: 'tiny') {
        width: calc(50% - 0.75em);
        margin-right: 1.5em;
      }
    }

    &--last-name {
      +mq(from: 'tiny') {
        width: calc(50% - 0.75em);
      }
    }

    // Address info
    &--zip-code {
      width: calc(30% - 0.5em);
      margin-right: 1.5em;

      +mq(until: 'tiny') {
        width: 40%;
        margin-right: 1em;
      }
    }

    &--city {
      width: calc(70% - 1em);

      +mq(until: 'tiny') {
        width: calc(60% - 1em);
      }
    }

    &--phone {
      +mq(from: 'tiny') {
        width: calc(50% - 0.75em);
        margin-right: 1.5em;
      }
    }

    &--org-number {
      +mq(from: 'tiny') {
        width: calc(50% - 0.75em);
      }
    }

    // Feedback, hidden as default
    &--feedback {
      margin-top: 0;
    }

    // Log in
    &--password {
      +mq(from: 'tiny') {
        width: calc(50% - 0.75em);
        margin-right: 1.5em;
      }
    }

    &--repeat-password {
      +mq(from: 'tiny') {
        width: calc(50% - 0.75em);
      }
    }

    // Collection info
    &--collectionName {
      +mq(from: 'tiny') {
        width: calc(65% - 1em);
        margin-right: 1.5em;
      }
    }

    &--collectionGoal {
      +mq(from: 'tiny') {
        width: calc(35% - 0.5em);
        margin-top: 0;
      }
    }

    // Social info
    &--social-details-avatar {
      width: 25%;
      padding-top: 25%;
      margin-right: 1.5em;
      height: 0;
      background: #fff center center;
      background-size: cover;
      border-radius: 50%;
      border: 3px solid #ddd;
    }

    &--social-details-name {
      width: calc(75% - 1.5em);
      margin-top: 0;
    }

    &--social-details-location {
      width: calc(75% - 1.5em);
    }

    &--social-details-name {
      width: calc(75% - 1.5em);
    }

    // Payment options
    &--bank {
      width: 25%;
      margin-top: 0;
      type-link();
      font('cancerfondensans-bold');
    }

    // Profile
    &--richProfileAvatar {
      +mq(until: 'tiny') {
        float: none;
        margin: 0 auto;
        max-width: 160px;
      }

      +mq(from: 'tiny') {
        width: 25%;
        margin-right: 1.5em;
      }
    }

    &--richProfileDescription {
      +mq(from: 'tiny') {
        width: calc(75% - 1.5em);
        margin-top: 0;
      }
    }

    &--richProfileHeading {
      type-h3();
      text-align: center;
      margin-bottom: 0.5em;

      +mq(from: 'tiny') {
        width: calc(75% - 1.5em);
        margin-top: 0;
      }
    }
  }
}
