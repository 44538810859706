
.formBanks {
  clearfix();
  border: 1px solid #aaa;
  border-radius: 0.25em;
  overflow: hidden;

  &__item {
    +mq(until: 'tiny') {
      border-top: 1px solid #aaa;

      &:first-child {
        border-top: 0;
      }

      &:first-child {
        border-top-left-radius: 0.15em;
        border-top-right-radius: 0.15em;
      }

      &:last-child {
        border-bottom-left-radius: 0.15em;
        border-bottom-right-radius: 0.15em;
      }
    }

    +mq(from: 'tiny') {
      float: left;
      width: 25%;
      border-left: 1px solid #aaa;

      &:first-child {
        border-left: 0;
      }

      &:first-child {
        border-top-left-radius: 0.15em;
        border-bottom-left-radius: 0.15em;
      }

      &:last-child {
        border-top-right-radius: 0.15em;
        border-bottom-right-radius: 0.15em;
      }
    }

    background: #fff;
    padding: 0 1em;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: lighten(colors.oceanBlue, 90%);
    }

    &.is-selected {
      background: lighten(colors.oceanBlue, 80%);
    }
  }

  &__logo {
    height: 3em;
    max-width: 140px;
    margin: 0 auto;
    background: center center no-repeat;
    background-size: contain;
    transition: opacity 0.3s ease;
    opacity: 0.6;

    .formBanks__item:hover {
      opacity: 1;
    }

    +mq(from: 'tiny') {
      height: 4em;
    }

    &--0 {
      background-image: url('../../../images/bank-logos/handelsbanken.png');
    }

    &--1 {
      background-image: url('../../../images/bank-logos/seb.png');
    }

    &--2 {
      background-image: url('../../../images/bank-logos/nordea.png');
    }

    &--3 {
      background-image: url('../../../images/bank-logos/swedbank.png');
    }
  }
}
