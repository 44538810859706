
//
// Heading
//
heading(fontWeight = 'bold') {
  if fontWeight == 'light' {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 300;
  } else {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }

  &--bold {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }

  &--normal {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 400;
  }

  &--light {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 300;
  }

  &--clickable {
    cursor: pointer;
  }

  em, b {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }

  &--br {
    br {
      +mq(until: 'medium') {
        display: none;
      }
    }
  }
}

heading1(fontWeight = 'bold') {
  heading(fontWeight);
  font-size: 1.625rem;
  line-height: 1.230769231;

  +mq(from: 'small') {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}

heading2(fontWeight = 'bold') {
  heading(fontWeight);
  font-size: 1.375rem;
  line-height: 1.227272727;

  +mq(from: 'small') {
    font-size: 2rem;
    line-height: 1.15625;
  }
}

heading3(fontWeight = 'bold') {
  heading(fontWeight);
  font-size: 1.125rem;
  line-height: 1.2222222222;

  +mq(from: 'small') {
    font-size: 1.375rem;
    line-height: 1.227272727;
  }
}

.heading3 {
  heading3();
}

heading4(fontWeight = 'bold') {
  heading(fontWeight);
  font-size: 1rem;
  line-height: 1.5;

  +mq(from: 'small') {
    font-size: 1.125rem;
  }
}

.heading4 {
  heading4();
}

heading5(fontWeight = 'bold') {
  heading(fontWeight);
  font-size: 1rem;
  line-height: 1.5;

  +mq(from: 'small') {
    font-size: 1.125rem;
  }
}

.heading5 {
  heading5();
}

//
// Preamble
//
preamble2(fontWeight = 'light') {
  heading(fontWeight);
  font-size: 1em;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;

  &--slimTop {
    padding-top: 0;
  }

  +mq(from: 'small') {
    font-size: 1.15em;
  }

  em, b {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }
}


//
// Description
//
description1() {
  font-size: 0.9375em;

  i {
    font-family: 'CancerfondenSans', sans-serif;
    font-style: italic;
  }

  em, b {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }
}

.description1 {
  description1();
}



//
// Description
//
description2() {
  font-size: 0.875em;

  i {
    font-family: 'CancerfondenSans', sans-serif;
    font-style: italic;
  }

  em, b {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }
}

.description2 {
  description2();
}



//
// Disclaimer text
//
disclaimerText() {
  font-size: 0.75em;

  i {
    font-family: 'CancerfondenSans', sans-serif;
    font-style: italic;
  }

  em, b {
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
  }
}

.disclaimerText {
  disclaimerText();
}
