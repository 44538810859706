
.radioGroup {
  input-group();
  table-layout: fixed;
  background: #fff;

  &--slim {
    margin-bottom: 0;
  }

  &__option {
    input-group-element();
    padding-top: 1em;
    padding-bottom: 1em;
    height: auto;
    line-height: 1;
    vertical-align: middle;
    text-align: center;
    border-left: 1px solid #aaa;
    cursor: pointer;
    font('cancerfondensans-bold');
    color: colors.text;
    transition: background 0.3s ease, color 0.3s ease;

    &--hiddenMobile {
      +mq(until: 'tiny') {
        display: none;
      }
    }

    &:hover {
      color: colors.oceanBlue;
      background: lighten(colors.oceanBlue, 90%);
    }

    &:first-child {
      border-left: 0;
    }

    &:first-child {
      border-top-left-radius: 0.15em;
      border-bottom-left-radius: 0.15em;
    }

    &:last-child {
      border-top-right-radius: 0.15em;
      border-bottom-right-radius: 0.15em;
    }

    &.is-selected {
      cursor: default;
      background: colors.oceanBlue;
      color: #fff;
    }

    &.is-disabled,
    &.is-disabled:hover {
      cursor: default;
      color: #ccc;
      background: #fafafa;
    }

    +mq(until: 'tiny') {
      .radioGroup--stackable & {
        border-left: 0;
        display: block;
        border-top: 1px solid #aaa;

        &:first-child {
          border-top: 0;
        }

        &:first-child {
          border-radius: 0;
          border-top-left-radius: 0.15em;
          border-top-right-radius: 0.15em;
        }

        &:last-child {
          border-radius: 0;
          border-bottom-left-radius: 0.15em;
          border-bottom-right-radius: 0.15em;
        }
      }
    }
  }
}
