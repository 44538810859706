
.formSharePreview {
  padding-top: 0.5em;

  &__title {
    type-h4();
    color: #666;
  }

  &__image {
    margin: 0 auto;
    max-width: 400px;
  }

  &__buttons {
    margin-top: 2em;
    text-align: center;
  }

  &__button {
    display: inline-block;
    padding: 0 0.5em;
  }

  &__listTitle {
    type-h4();
    font('cancerfondensans-bold');
    margin-top: 2em;
  }

  &__list {
    margin: 1em auto 0 auto;
    text-align: center;

    +mq(until: 'small') {
      max-width: 320px;
    }
  }

  &__item {
    position: relative;
    display: inline-block;
    width: 6em;
    margin: 0 0.25em;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    &.is-selected {
      opacity: 1;
    }
  }

  &__itemImage {
    display: block;
    max-width: 100%;
  }
}



.formShareDetails {
  background: #fafafa;
  border: 1px solid #ddd;
  padding: 2em;

  &__title {
    type-h2();
    margin-bottom: 0.25em;
    text-align: center;
  }

  &__preamble {
    max-width: 620px;
    margin: 0 auto;
    font-size: 1.1em;
    text-align: center;
    margin-bottom: 1.25em;
  }

  &__form {
    margin-top: 1.5em;
  }
}
