.spinner {
  &__circle {
    spinner(1.5em, 2px);
    display: inline-block;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    margin-right: 1em;
    vertical-align: middle;
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
  }
}
