
.form {
  position: relative;

  &__section {
    margin-top: 2.5em;

    &--slim {
      +mq(from: 'small') {
        margin-top: 0;
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__title {
    type-h2();
    margin-bottom: 0.2em;
  }

  &__subtitle {
    type-h4();
  }

  &__preamble {
    margin-bottom: 1.5em;
  }

  &__description {
    type-subtle();
    margin-bottom: 0.5em;
  }

  &__option {
    clearfix();
    padding-top: 0.8em;
  }

  &__member {
    max-width: 420px;
    margin: 0 auto;
  }

  &__approval {
    max-width: 420px;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }

  &__submit {
    border-top: 1px solid #ddd;
    padding-top: 2em;
    margin-top: 2em;
  }

  &__button {
    text-align: center;

    &--multiple {
      display: inline-block;
      margin: 0 0.5em;

      +mq(until: 'small') {
        display: block;
        margin: 1em 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__disclaimer {
    type-subtle();
    text-align: center;
    color: #888;
    max-width: 600px;
    margin: 2em auto 0;
  }
}
