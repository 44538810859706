
.sectionInfo {
  text-align: center;

  > * {
    margin-top: 0.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  > noscript:nth-child(1) + * {
    margin-top: 0;
  }

  &--marginBottom {
    margin-bottom: 2.5em;
  }

  &__title {
    text-transform: uppercase;
    
    &--large,
    &--normal {
      heading3();
    }

    &--small {
      heading4();
    }

    +mq(until: 'small') {
      br {
        display: none;
      }
    }
  }

  &__preamble {
    font('cancerfondensans-light');
    font-size: 1em;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    max-width: 780px;

    &--slimTop {
      padding-top: 0;
    }

    +mq(from: 'small') {
      font-size: 1.15em;
    }

    +mq(until: 'small') {
      br {
        display: none;
      }
    }

    em {
      font('cancerfondensans-bold');
    }
  }

  &__description {
    font-size: 0.9375em;
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;

    +mq(until: 'small') {
      br {
        display: none;
      }
    }
  }
}
