
.relatedBox {
  border: 3px solid #ddd;
  padding: 1.5em;

  &__heading {
    font('cancerfondensans-bold');

    text-transform: uppercase;
    margin-bottom: 0.75em;
    padding-bottom: 0.75em;
    border-bottom: 1px solid #ddd;
  }

  &__list {
    clearfix();
  }

  &__listItem {
    text-truncate();

    margin-bottom: 0.25em;
    color: #888;

    &--twoColumn {
      +mq(from: 'tiny') {
        layout-grid(2, 0.5em);
      }
    }
  }

  &__link {
    font('cancerfondensans-bold');
  }

  &__separator {
    padding: 0 0.5em;
    color: #000;
  }

  &__preamble {
    color: #888;
  }
}
