
.centeredList {
  text-align: center;
  margin: -2em auto 0;

  &--twoUp {
    margin-left: -1.5em;
    margin-right: -1.5em;
  }

  &--stackSmall {
    +mq(until: 'small') {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__item {
    padding: 2em 1.5em 0;
    display: inline-block;
    vertical-align: top;
    max-width: 280px;

    &--gutterTight {
      padding-left: 0.75em;
      padding-right: 0.75em;
    }

    &--narrow,
    .centeredList--narrowItem & {
      max-width: 160px;
    }

    &--wide,
    .centeredList--wideItem & {
      max-width: 360px;
    }

    .centeredList--twoUp & {
      width: 50%;
      max-width: none;
    }

    .centeredList--stackSmall & {
      +mq(until: 'small') {
        width: 100%;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  //
  // Width
  //
  &--narrow,
  &--widthNarrow {
    max-width: 940px;
  }

  //
  // Spacing
  //
  &--spacingTight {
    margin-top: -1em;
  }

  &__item {
    ^[0]--spacingTight & {
      padding-top: 1em;
    }
  }
}
